import React from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import '../styles/Editor.css';
import '../scss/main.scss';
import 'draft-js/dist/Draft.css';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import '../styles/Notification.scss';
import { ProgressBarProvider } from '../context/ProgressBarContext';
import { AlertProvider } from '../context/AlertContext';
import { isEditModeVar } from '../reactivities/vars/isEditModeVar';

import { useEffect, useState } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';

function MyAuthenticatedApp({ Component, pageProps }: any) {
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isEditMode: {
            read() {
              return isEditModeVar();
            },
          },
        },
      },
    },
  });
  // Because `getAccessTokenSilently` is async, we need the apolloClient to be managed in state
  // Setup default unauthenticated apolloClient
  const [apolloClient, setApolloClient] = useState(() => {
    return {
      client: new ApolloClient({
        ssrMode: typeof window === 'undefined',
        uri: process.env.NEXT_PUBLIC_APOLLO_URI,
        cache,
      }),
      authenticated: false,
      hastempid: false,
    };
  });
  // Setup new apolloClient if the isAuthenticated state changes
  useEffect(() => {
    const createNewApolloClientIfRequired = async () => {
      let token: string | null = null;
      let tempuserid = localStorage.getItem('ss.assemble.anonymous');
      if (!tempuserid) {
        tempuserid = uuidv4();
        localStorage.setItem('ss.assemble.anonymous', uuidv4());
      }
      let hasAuthenticated = localStorage.getItem('ss.assemble.hasAuthenticated');
      if (isAuthenticated) {
        localStorage.setItem('ss.assemble.hasAuthenticated', '1');
        token = await getAccessTokenSilently();
      } else if (hasAuthenticated && !isLoading) {
        localStorage.removeItem('ss.assemble.hasAuthenticated');
        loginWithRedirect();
      }
      setApolloClient(currentClient => {
        if (currentClient.authenticated === isAuthenticated && currentClient.hastempid) {
          return currentClient;
        }

        const httpLink = createHttpLink({
          uri: process.env.NEXT_PUBLIC_APOLLO_URI,
        });

        const authLink = setContext((_, { headers }) => {
          // get the authentication token from local storage if it exists
          // return the headers to the context so httpLink can read them
          return {
            headers: {
              ...headers,
              tempuserid,
              authorization: token ? `Bearer ${token}` : '',
            },
          };
        });

        const webSocketUri = process.env.NEXT_PUBLIC_APOLLO_WEBSOCKET_URI || `ws://localhost:4002/graphql`;
        const wsLink = new WebSocketLink({
          uri: webSocketUri,
          options: {
            reconnect: true,
            connectionParams: {
              authorization: token ? `Bearer ${token}` : '',
              tempuserid,
            },
          },
        });

        const splitLink = split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
          },
          wsLink,
          authLink.concat(httpLink),
        );

        return {
          client: new ApolloClient({
            link: splitLink,
            cache,
          }),
          authenticated: isAuthenticated,
          hastempid: true,
        };
      });
    };
    createNewApolloClientIfRequired();
  }, [isAuthenticated, getAccessTokenSilently, cache]);

  // Apollo client is ready once the page has loaded and we can read window, or if there is no window, and we're doing server-side rendering
  const apolloClientIsReady = typeof window === 'undefined' || apolloClient.authenticated || apolloClient.hastempid;
  if (apolloClientIsReady)
    return (
      <ApolloProvider client={apolloClient.client}>
        <AlertProvider>
          <ProgressBarProvider>
            <Component {...pageProps} />
          </ProgressBarProvider>
        </AlertProvider>
      </ApolloProvider>
    );
  return null;
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  let redirectUri = process.env.NEXT_PUBLIC_ORIGIN + router.pathname;
  if (typeof window !== 'undefined') {
    redirectUri = window.location.origin;
  }
  return (
    <Auth0Provider
      domain="auth.simonsays.ai"
      clientId="EM8jHHQQ0nlGAzF2r4KN20Qdx2DU89eY"
      audience="https://simonsays.ai"
      redirectUri={redirectUri}
    >
      <MyAuthenticatedApp Component={Component} pageProps={pageProps} />
    </Auth0Provider>
  );
}

export default MyApp;
